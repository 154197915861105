<template>
  <div class="register-page">
    <div class="top">
      <div class="description">
        <div class="hello">你好，</div>
        <div class="content">
          <span class="text">您正在注册{{ systemInfo.company }}账号</span>
          <router-link :to="`/login?redirect=${redirect}`" class="link"
            >去登录</router-link
          >
        </div>
      </div>
      <div class="form-container">
        <van-form
          key="checkForm"
          class="check-form"
          ref="checkForm"
          validate-first
          :show-error="false"
          v-if="!showRegisterForm"
        >
          <van-field
            v-model="registerModel.telephone"
            name="telephone"
            :placeholder="isZdd ? '请输入手机号' : '请输入手机号/邮箱'"
            ref="telephone"
            :clearable="true"
            :show-error="false"
            :error="false"
            :rules="rules.telephone"
          ></van-field>
          <van-field
            v-model="checkModel.imgCode"
            name="imgCode"
            placeholder="请输入图片验证码"
            :maxlength="4"
            :rules="rules.imgCode"
          >
            <template #button>
              <div class="imgCode-container" @click="getPictureCode">
                <img :src="pictureCode.img" alt="imgCode" />
              </div>
            </template>
          </van-field>
          <van-checkbox
            class="check"
            :label-disabled="true"
            v-model="isChecked"
          >
            我已同意
            <a
              class="link agreement-link"
              v-for="item in agreementList"
              :key="item.title"
              @click="handleShowAgreement(item.title)"
              >《{{ item.title }}》</a
            >
          </van-checkbox>
        </van-form>

        <van-form
          key="registerForm"
          class="register-form"
          ref="registerForm"
          validate-first
          :show-error="false"
          v-else
        >
          <van-field
            v-model="registerModel.telephone"
            name="telephone"
            :placeholder="isZdd ? '请输入手机号' : '请输入手机号/邮箱'"
            ref="telephone"
            :clearable="true"
            :show-error="false"
            :error="false"
            :rules="rules.telephone"
            disabled
          ></van-field>
          <van-field
            v-model="registerModel.password"
            name="password"
            placeholder="请输入密码"
            type="password"
            ref="password"
            :show-error="false"
            :error="false"
            :rules="rules.password"
          />
          <van-field
            v-model="registerModel.telCode"
            name="telCode"
            placeholder="请输入验证码"
            type="number"
            ref="telCode"
            :show-error="false"
            :error="false"
          >
            <template #button>
              <div
                class="get-code"
                :class="{ disabled: isTelCodeButtonBanned }"
                @click="getTelCode"
                v-if="!getTelCodeButtonLoading"
              >
                {{ telCodeButtonText }}
              </div>
              <van-loading v-else />
            </template>
          </van-field>
        </van-form>
      </div>
    </div>
    <div class="bottom" v-if="!isPopup">
      <div class="button-container">
        <van-button
          round
          type="primary"
          :disabled="!isChecked"
          @click="handleClickNextButton"
          v-if="!showRegisterForm"
          >下一步</van-button
        >
        <van-button round type="primary" @click="handleRegister" v-else
          >注册</van-button
        >
      </div>
    </div>
    <Agreement v-model="showAgreement" :title="curAgreementTitle" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { validateMobileNumber, validateEmail } from '@/utils/validate'
  import { resetCookies } from '@/utils/cookies'
  import api from '@/api/passport'
  import Agreement from '@/components/Agreement/index.vue'

  export default {
    name: 'Register',
    components: { Agreement },
    props: {
      isPopup: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      this.agreementList = [
        {
          title: '服务协议',
        },
        {
          title: '隐私协议',
        },
        {
          title: '证书服务协议',
        },
      ]
      return {
        // 展示协议页面
        showAgreement: false,
        curAgreementTitle: '服务协议',
        rules: {
          telephone: [
            {
              validator: this.validateAccount,
              trigger: 'onBlur',
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号',
              trigger: 'onBlur',
            },
          ],
          imgCode: [
            {
              required: true,
              message: '请输入图片验证码',
              trigger: 'onBlur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'onBlur',
            },
            {
              validator: this.validatePassword,
              trigger: 'onBlur',
              message: '密码长度为8-16位',
            },
          ],
        },
        // 是否勾选同意协议
        isChecked: false,
        // 图片验证码
        pictureCode: {
          // 验证码图片base64
          img: undefined,
          // 对应的验证码
          code: undefined,
        },
        checkModel: {
          code: undefined,
          imgCode: undefined,
        },
        registerModel: {
          password: undefined,
          telCode: undefined,
          telephone: undefined,
          type: 0, // 0-注册
        },
        isCheckError: false,
        showRegisterForm: false,
        nextButtonLoading: false, // 下一步按钮loading
        getTelCodeButtonLoading: false, // 获取验证码的loading
        registerButtonLoading: false, // 注册按钮的loading
        isTelCodeButtonBanned: false, // 是否禁用获取验证码按钮
        getTelCodeTimer: null, // 获取验证码按钮定时器
        telCodeButtonText: '获取验证码',
        getTelCodeCountDown: 60,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    filters: {},
    created() {
      resetCookies()
      this.fetchData()
      const { query } = this.$route
      if (query) {
        this.redirect = query.redirect
      }
    },
    methods: {
      fetchData() {
        this.getPictureCode()
      },

      // 验证手机号
      validateAccount(val) {
        if (this.isZdd) {
          if (this.isMobile(val)) {
            return this.validateMobile(val)
          }
          return false
        }
        if (this.isMobile(val)) {
          return this.validateMobile(val)
        }
        if (this.isEmail(val)) {
          return this.validateEmailAccount(val)
        }
        return false
      },
      // 验证手机号
      validateMobile(val) {
        return validateMobileNumber(val)
      },
      // 验证邮箱
      validateEmailAccount(val) {
        return validateEmail(val)
      },
      validatePassword(val) {
        return !(val.length < 8 || val.length > 16)
      },

      // mobileFormat(val) {
      //   if (val) {
      //     if (val.length === 3 || val.length === 8) {
      //       return `${val} `;
      //     } if (val.length === 9 || val.length === 4) {
      //       return val.trim();
      //     }
      //   }
      //   return val;
      // },
      handleShowAgreement(title) {
        this.showAgreement = true
        this.curAgreementTitle = title
      },

      // 获取图片验证码
      async getPictureCode() {
        try {
          const { code, img } = await api.getPictureCode()
          this.pictureCode = {
            ...this.pictureCode,
            code,
            img,
          }
          this.checkModel.code = code
        } catch (error) {
          console.error(error)
        }
      },

      // 验证表单项
      async validateField(formName, name) {
        let isPassed = true
        try {
          await this.resetValidation(formName, name)
          await this.$refs[formName].validate(name)
        } catch (error) {
          isPassed = false
          // if (error) this.$toast(error);
        }
        return isPassed
      },

      // 重置表单项的验证提示
      async resetValidation(formName, name) {
        try {
          await this.$refs[formName].resetValidation(name)
        } catch (error) {
          // if (error) this.$toast(error);
        }
      },

      // 验证账号是否注册和图片验证码
      async checkAccountAndPictureCode() {
        try {
          this.isCheckError = false
          const model = {
            telephone: this.registerModel.telephone,
            code: this.pictureCode.code,
            imgCode: this.checkModel.imgCode,
          }
          const res = await api.checkAccountAndPictureCode(model)
          const { registStatus, verifyCodeStatus } = res
          if (registStatus) {
            this.$toast('手机号已经注册')
          } else if (!verifyCodeStatus) {
            this.$toast('图形验证码错误')
          }
          // 只要验证不通过，就不能进入后续的注册页面
          if (registStatus || !verifyCodeStatus) {
            this.isCheckError = true
            await this.getPictureCode()
          }
        } catch (error) {
          this.isCheckError = true
          await this.getPictureCode()
          console.error(error)
        }
      },

      // 点击下一步按钮
      async handleClickNextButton() {
        try {
          const isPassed = await this.validateField('checkForm', 'telephone')
          if (isPassed) {
            await this.checkAccountAndPictureCode()
            if (!this.isCheckError) {
              this.showRegisterForm = true
            }
          }
        } catch (error) {
          console.error(error)
        }
      },
      // 验证输入的值是手机还是邮箱
      isMobile(value) {
        return /^\d+$/g.test(value)
      },
      isEmail(value) {
        return /[a-zA-Z@]/g.test(value)
      },
      // 获取手机验证码
      async getTelCode() {
        try {
          // 在倒计时直接跳出
          if (this.getTelCodeTimer) return
          const isPassed = await this.validateField('registerForm', 'password')
          if (isPassed) {
            this.getTelCodeButtonLoading = true
            const model = {
              telephone: this.registerModel.telephone,
              type: 0, // 0 注册
            }
            if (this.isMobile(model.telephone)) {
              await api.getTelCode(model)
            } else if (this.isEmail(model.telephone)) {
              await api.getEmailCode({
                email: model.telephone,
                type: 0,
                systemId: this.systemInfo.systemId,
              })
            }
            // await api.getTelCode(model)
            this.changeTelCodeButtonText()
            this.$toast({
              message: '验证码发送成功，请注意查收',
              type: 'success',
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.getTelCodeButtonLoading = false
        }
      },

      // 修改获取验证码按钮文本
      changeTelCodeButtonText() {
        const defaultCountDown = this.getTelCodeCountDown
        this.isTelCodeButtonBanned = true
        this.telCodeButtonText = `重新发送(${this.getTelCodeCountDown})`
        this.getTelCodeTimer = setInterval(() => {
          if (this.getTelCodeCountDown === 0) {
            this.isTelCodeButtonBanned = false
            clearInterval(this.getTelCodeTimer)
            this.getTelCodeTimer = null
            this.telCodeButtonText = '获取验证码'
            this.getTelCodeCountDown = defaultCountDown
          } else {
            this.getTelCodeCountDown -= 1
            this.telCodeButtonText = `重新发送(${this.getTelCodeCountDown})`
          }
        }, 1000)
      },

      // 点击注册
      async handleRegister() {
        try {
          const isPassed = this.validateField('registerFrom', 'password')
          if (isPassed) {
            const model = this.registerModel
            model.systemId = this.systemInfo.systemId
            const data = await api.registerByTel(model)
            await this.$toast({
              message: '注册成功',
              type: 'success',
            })
            const payload = {
              userInfo: {
                ...data,
                userId: data.uid,
              },
            }
            this.$store.commit('setUserInfo', payload)
            if (!this.redirect) {
              this.$router.push({
                path: '/login',
              })
              return
            }
            this.$router.push({
              path: this.redirect,
            })
          }
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .register-page {
    width: 100%;
    max-height: 100vh;
    padding: 63px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .description {
      font-weight: 400;
      margin-bottom: 43px;

      .hello {
        font-size: 36px;
        text-align: left;
        color: @TEXT-COLOR-0;
      }

      .content {
        margin-top: 21px;
        font-size: 18px;
        color: @TEXT-COLOR-1;

        .link {
          margin-left: 8px;
          color: @BLUE;
        }
      }
    }

    .form-container {
      .check-form {
        .imgCode-container {
          width: 96px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        /deep/ .van-checkbox {
          margin-top: 15px;
          line-height: 24px;
          align-items: flex-start;

          .van-checkbox__icon {
            width: 16px;
            height: 16px;

            .van-icon {
              width: 16px;
              height: 16px;
              font-size: 16px;
              line-height: 16px;
            }
          }
          .van-checkbox__label {
            font-size: 16px;
          }
        }
      }

      .register-form {
        .get-code {
          font-size: 16px;
          color: @BLUE;

          &.disabled {
            color: @TEXT-COLOR-2;
          }
        }
      }
    }

    .button-container {
      width: 100%;

      /deep/ .van-button {
        width: 100%;

        .van-button__text {
          color: @WHITE;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  /deep/ .van-form {
    .van-cell {
      margin-top: 15px;
      padding: 0;
      font-size: 16px;
      color: @TEXT-COLOR-0;

      &.van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: @TEXT-COLOR-0;
        // font-size: 16px;
      }

      &::after {
        display: none;
      }

      .van-field__body {
        border-bottom: 1px solid @BORDER-COLOR-0;
        padding: 14px 0;
      }

      .van-field__error-message {
        font-size: 12px;
        color: @RED;
      }
    }
  }
</style>
